import React from "react";

const PmsTermsAnditions = () => {
  return (
    <div className="p-10  pr-44 gap-5">
      <h1 className="text-2xl mb-5 font-semibold">Terms And Conditions</h1>
      <h2 className="text-xl font-semibold">Privacy Policy</h2>
      <p>Effective Date: 1.2.2025</p>
      <p>
        OBS Property Management System ("we," "our," or "us") is committed to
        protecting your privacy. This Privacy Policy explains how we collect,
        use, disclose, and safeguard your information when you use our mobile
        application ("App").
      </p>
      <p>
        By using our App, you agree to the collection and use of information in
        accordance with this policy. If you do not agree, please do not use our
        services.
      </p>
      <h3 className="text-lg mb-2 mt-5 font-semibold">1. Information We Collect</h3>
      <h4>A. Personal Information</h4>
      <p>When you register or use our services, we may collect:</p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
      </ul>
      <h4>B. Property & Tenant Information</h4>
      <p>To help you manage your properties, we collect:</p>
      <ul>
        <li>Property details (address, type, rental price, etc.)</li>
        <li>
          Tenant details (name, contact information, lease agreements, payment
          history, utility information)
        </li>
      </ul>
      <h3 className="text-lg mb-2 mt-5 font-semibold">2. How We Use Your Information</h3>
      <p>We use collected information to:</p>
      <ul>
        <li>Provide and maintain our services</li>
        <li>Manage property and tenant records</li>
        <li>Process payments securely</li>
        <li>Send notifications, invoices, and service updates</li>
        <li>Improve user experience and develop new features</li>
        <li>Ensure security and prevent fraud</li>
      </ul>
      <h3 className="text-lg mb-2 mt-5 font-semibold">3. How We Share Your Information</h3>
      <p>
        We do not sell your personal data. However, we may share information in
        the following cases:
      </p>
      <ul>
        <li>
          With Service Providers: Payment processors, cloud storage providers,
          and analytics services.
        </li>
        <li>
          For Legal Compliance: If required by law, to protect rights, or to
          enforce policies.
        </li>
        <li>
          With Business Partners: If integrated with third-party services (e.g.,
          KBZ Pay for payments).
        </li>
      </ul>
      <h3 className="text-lg mb-2 mt-5 font-semibold">4. Data Security</h3>
      <p>
        We take reasonable steps to protect your information using encryption,
        secure servers, and access controls. However, no method of transmission
        over the internet is 100% secure.
      </p>
      <h3 className="text-lg mb-2 mt-5 font-semibold">5. Your Rights & Choices</h3>
      <p>You can:</p>
      <ul>
        <li>
          Access, update, or delete your personal data through your account
          settings.
        </li>
        <li>Opt out of marketing emails and notifications.</li>
        <li>Request data deletion by contacting us.</li>
      </ul>
      <h3 className="text-lg mb-2 mt-5 font-semibold">6. Third-Party Services</h3>
      <p>
        Our App may integrate with third-party services (e.g., payment
        gateways). Their privacy policies govern how they handle your data.
      </p>
      <h3 className="text-lg mb-2 mt-5 font-semibold">7. Children's Privacy</h3>
      <p>
        Our App is not intended for users under 13 years old. We do not
        knowingly collect data from children.
      </p>
      <h3 className="text-lg mb-2 mt-5 font-semibold">8. Changes to This Policy</h3>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of changes via the App or email. Continued use of our services means you
        accept the updated policy.
      </p>
      <h3 className="text-lg mb-2 mt-5 font-semibold">9. Contact Us</h3>
      <p>For questions or concerns about this Privacy Policy, contact us at:</p>
      <p>📧 Email: info@obs.com.mm</p>
      <p>🌐 Website: https://obs.com.mm</p>
    </div>
  );
};

export default PmsTermsAnditions;
